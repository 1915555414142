





































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import { currencyFormat, loadWidget } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import WidgetMixins from '@/mixins/WidgetMixins';
import { namespace } from 'vuex-class';
import ToolBar from '@/components/widgets/ToolBar.vue';
import { INotifications, IGraphData, IOrganizations } from '@/types/types';
import qs from 'qs';
//firebase
const appModule = namespace('apps');
const notificationModule = namespace('notifications');
const organizationModule = namespace('organizations');
const dashboardModule = namespace('dashboard');
@Component({
  components: {
    ToolBar,
    Notifications: loadWidget('pages/Notifications'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    'internet-connection': loadWidget('pages/InternetConnection'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    SnackBar: loadWidget('widgets/SnackBar'),
  },
  filters: {
    currencyFormat,
  },
})
export default class Applications extends mixins(WidgetMixins) {
  placeholder: string = require('@/assets/images/notification-list.jpg');
  @appModule.Getter('getApps') dashboard!: Array<object>;
  @dashboardModule.Getter('getCUDashboardStatistics') dashboardCount!: any;
  @dashboardModule.Getter('getCUDashboardGraph') dashboardGraph!: any;
  @organizationModule.Getter('getAllOrganizations') organizations!: Array<IOrganizations>;

  @notificationModule.Getter('getAllNotifications')
  notifications!: ReadonlyArray<INotifications>;

  showPushNotificationToast = false;
  markAsRead: boolean = true;
  tab: any = null;
  text: string = 'sample test';
  items: Array<string> = ['All Updates', 'Popular'];
  show: boolean = false;
  date: string = new Date().toISOString().substr(0, 10);

  startDateMenu: boolean = false;
  endDateMenu: boolean = false;
  startDate: string | null = null;
  endDate: string | null = null;
  query: object | null = {};

  statuses: Array<{ text: string; value: string }> = [
    { text: 'DAILY', value: '$date' },
    { text: 'MONTHLY', value: '$month' },
    { text: 'YEARLY', value: '$year' },
  ];
  paymentStatuses: Array<string> = ['SUCCESS', 'FAILED'];
  paymentStatus: string = 'SUCCESS';
  status: string = '$date';
  organizationId: string | null = '';

  chartOptions: any = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        endingShape: 'rounded',
      },
    },
    colors: ['#e20639', '#04bf5b'],
    legend: {
      show: true,
      fontSize: '11px',
      fontWeight: '500',
      fontFamily: 'Montserrat, sans-serif',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          cssClass: 'ft',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Total Customers',
      },
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          cssClass: 'ft',
        },
        formatter: (val: any) => {
          return val;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  areaChartOptions: any = {
    chart: {
      type: 'area',
      height: 350,
    },
    dataLabels: {
      enabled: true,
    },
    colors: ['#2FD806'],
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Total Transactions',
      },
    },
  };

  series: any = [
    {
      name: 'Total Customers',
      data: [],
    },
  ];

  transactionsSeries: any = [
    {
      name: 'Total Transactions',
      data: [],
    },
    // {
    //   name: 'Total Approved IDs',
    //   data: [],
    // },
  ];

  isNotificationTurnedOn = false;
  isNotificationDenied = false;
  headers: any = [
    {
      text: 'Date',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'Customer', value: 'calories' },
    { text: 'Products', value: 'fat' },
    { text: 'Details', value: 'carbs' },
    { text: 'Price', value: 'protein' },
    { text: 'Delivery Cost', value: 'iron' },
  ];

  emptyState: any = {
    notification: require('@/assets/images/music.png'),
    popular: require('@/assets/images/commerce-and-shopping.png'),
  };

  @Watch('organizations', {
    deep: true,
  })
  onOrganizationListChange(payload: Array<IOrganizations>): void {
    this.organizationId = payload.length > 0 ? payload[0]?._id : null;
  }

  @Watch('dashboardGraph')
  onDashboardGraphChange(payload: any): void {
    const xAxis = [...new Set([...payload.organizationByMemberCountGraph.map((item: IGraphData) => item.label)])];
    this.series[0].data = payload.organizationByMemberCountGraph.map((item: IGraphData) => item.value);
    this.chartOptions = {
      ...this.chartOptions,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: xAxis,
      },
    };

    const transactionXAxis = [
      ...new Set([...payload.organizationCollectionsByPeriod.map((item: IGraphData) => item.label)]),
    ];
    this.areaChartOptions = {
      ...this.areaChartOptions,
      xaxis: {
        categories: transactionXAxis,
      },
    };
    this.transactionsSeries[0].data = payload.organizationCollectionsByPeriod.map((item: IGraphData) => item.value);
  }

  @Watch('organizationId')
  onOrganizationIDChange(id: string): void {
    this.query = { ...this.query, paymentStatus: this.paymentStatus, groupBy: this.status, organizationId: id };
    this.$store.dispatch('dashboard/creditUnionDashboardGraph', `?${qs.stringify(this.query)}`);
  }
  @Watch('paymentStatus')
  onPaymentStatusChange(status: string): void {
    this.areaChartOptions.colors = [status === 'FAILED' ? '#F81709' : '#2FD806'];
    this.query = { ...this.query, paymentStatus: status };
    this.$store.dispatch('dashboard/creditUnionDashboardGraph', `?${qs.stringify(this.query)}`);
  }
  @Watch('status')
  onStatusChange(state: string): void {
    this.query = { ...this.query, groupBy: state };
    this.$store.dispatch('dashboard/creditUnionDashboardGraph', `?${qs.stringify(this.query)}`);
  }
  @Watch('startDate')
  onStartDateChange(date: string): void {
    this.query = { ...this.query, startDate: date };
    this.$store.dispatch('dashboard/creditUnionDashboardGraph', `?${qs.stringify(this.query)}`);
  }
  @Watch('endDate')
  onEndDateChange(date: string): void {
    this.query = { ...this.query, endDate: date };
    this.$store.dispatch('dashboard/creditUnionDashboardGraph', `?${qs.stringify(this.query)}`);
  }

  // turning on notifications here

  // getting notifications token

  //sending notifications to server

  removeNotification(id: string): void {
    this.$store.dispatch('notifications/removeNotifications', { id });
  }

  clearAllNotifications(): void {
    this.$store.dispatch('notifications/clearAllNotifications');
  }

  markNotificationAsRead(notificationId: string): void {
    this.$store.dispatch(`notifications/markAsRead`, { id: notificationId });
  }

  created() {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('dashboard/creditUnionDashboardStatistics');

    this.$store.dispatch('organizations/list', false);
  }
}
